import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderRoutingModule } from './loader-routing.module';
import { LoaderComponent } from './loader.component';
import {SkeletonModule} from 'primeng/skeleton';


@NgModule({
  declarations: [LoaderComponent],
  imports: [
    CommonModule,
    LoaderRoutingModule,
    SkeletonModule
  ],
  exports: [LoaderComponent]
})
export class LoaderModule { }
