
 <p-dropdown [options]="countryList" [ngClass]="{'form-control-dropdown': styleClassCustom === 'form-control-dropdown'}" [(ngModel)]="selectedCountry" (onChange)="selectCountry()" class="countryDialcode" optionLabel="dialCode" [filter]="true" filterBy="dialCode,name" [showClear]="true" placeholder="Select a Country">
  <ng-template pTemplate="selectedItem">
      <div class="country-item country-item-value"  *ngIf="selectedCountry">
          <img [src]="selectedCountry?.flag" [class]="'flag flag-de'" />
          <!-- <div>{{selectedCountry?.name}} - </div> -->
          <div> {{selectedCountry?.dialCode}}</div>
      </div>
  </ng-template>
  <ng-template let-country pTemplate="item">
      <div class="country-item">
          <!-- <img [src]="country?.flag" [class]="'flag flag-de'" /> -->
          <div>{{country?.name}} - </div>
          <div> {{country?.dialCode}}</div>
      </div>
  </ng-template>
</p-dropdown>
