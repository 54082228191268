import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  urlPath: string;
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.urlPath = this.getUrlPath(request);
    let Request;
    if (request.url.includes('https://free.currconv.com/') || request.url.includes('https://api.currconv.com/')) {
      Request = request.clone({ url: this.urlPath});
    }
    else if (request.url.includes('verify/email/otp') || request.url.includes('verify/email')){
      const tocken = localStorage.getItem('accessValidation') ? localStorage.getItem('accessValidation') : localStorage.getItem('tocken');
      Request = request.clone({ url: this.urlPath, setHeaders: {Authorization: `Bearer ${tocken}`} });
    }
    else{
      Request = request.clone({ url: this.urlPath, setHeaders: {Authorization: `Bearer ${localStorage.getItem('tocken')}`} });
    }

    return next.handle(Request);
  }

  /** Helper function to switch url */
  getUrlPath = ({ url }) => {
    const { URL } = environment;
    // * If the current call is to admission.
    if (url.includes('https://countriesnow.space/')) {
      return `${url}`;
    }
    else if (url.includes('https://free.currconv.com/') || url.includes('https://api.currconv.com/')) {
      return `${url}`;
    }else if(url.includes('https://gist.githubusercontent.com/')){
      return `${url}`;
    }

    return `${URL}${url}`;
  }
}
