import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

let headers;

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(
    private http: HttpClient
  ) {
  }

  UserRegister = (sendingData): Observable<any> => {
    const data = {
      ...sendingData,
      password: sendingData.pwd
    }
    return this.http.post<any>('user_register', data, { headers });
  }

  
}
