import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { ProductSearchModel } from './models/product.model';
import { MyAccountService } from './my-account/my-account.service';
import { UserWalletService } from './user-wallet/user-wallet.service';
import * as CryptoJS from 'crypto-js';
import { GlobalUrls } from './global-url';


let headers;
declare var $;

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
 private productSearchData = new Subject<ProductSearchModel>();
 private notificationData = new Subject<any>();
 private countries = [];
 private countryStates = [];
 private selectedCountry: any;
 private userWallet = 0;
 private defaultCurrency = '';
 private savedCurrency = '';
 private convertedAmount = 0;
 private userProfile: any;
 private directAdminChat = false;
 private activePaymentFlag = false;
  constructor(private http: HttpClient, private productModel: ProductSearchModel,
              private myAccount: MyAccountService, private globalVar: GlobalUrls, private router: Router,
              private WalletService: UserWalletService) {
    this.getSiteSetting(null);
  }
  setSearchDataList(params){
    this.productSearchData.next(params);
  }
  getSearchDataList = (): Observable<any> => {
    return this.productSearchData.asObservable();
  }

  setNotificationData(params){
    this.notificationData.next(params);
  }

  getNotificationDataList = (): Observable<any> => {
    return this.notificationData.asObservable();
  }
  encodeUrlParamData(data) {
    const encodeData: string = CryptoJS.AES.
    encrypt(JSON.stringify(data), 's~j_)*&^%^^~ithry*&%$^#&*%$go8t&(^%^&%^&*3w+65oyt78eytIT*^^&%`').toString();
    /* encodeData = encodeData.replace('/', '~'); */
    return encodeData.split('/').join('~');
  }
  decodeUrlParamData(data: string) {
    data = data.split('~').join('/');
    const encodeData = CryptoJS.AES.decrypt(data, 's~j_)*&^%^^~ithry*&%$^#&*%$go8t&(^%^&%^&*3w+65oyt78eytIT*^^&%`');
    const decriptData = JSON.parse(encodeData.toString(CryptoJS.enc.Utf8) || '{}');
    return decriptData;
  }

  isLoggedInOrNot() {
    const userId = localStorage.getItem('userId');
    if (typeof userId === 'undefined' || userId === null || userId === ''){
      $('#popup-login').show();
      $('#popup-cnt').show(100);
      return false;
    }
    return userId;
  }

  loadCountries = (): Observable<any> => {
    return this.http.get<any>(`https://countriesnow.space/api/v0.1/countries/info?returns=currency,flag,dialCode`);
  }
  saveCountries(countryList) {
    this.countries = countryList;
  }
  getCountries() {
    this.countries.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    return this.countries;
  }
  loadCountryStates = (): Observable<any> => {
    return this.http.get<any>(`https://countriesnow.space/api/v0.1/countries/states`);
  }

  convertCurrency(currencyCode, navigate=null){
    const opts = { params: new HttpParams({fromString: `q=AED_${currencyCode}&compact=ultra&apiKey=${this.globalVar.currencyAPIKey}`}) };
    this.http.get<any>(`https://free.currconv.com/api/v7/convert`, opts)
    .subscribe((response): void => {
      this.saveConvertedCurrency(response["AED_"+currencyCode]);
      if (navigate !== null) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          // Navigate to the current URL, effectively reloading the route
          this.router.navigateByUrl(navigate);
        });
      }
    },
    (error) => {
      this.saveConvertedCurrency(1);
    });
  }
  saveConvertedCurrency(amount) {
    localStorage.setItem('rate', amount);
    const nowTime = new Date().getTime().toString();
    localStorage.setItem('lastCurrencyConvert', nowTime);
  }
  getConvertedCurrency() {
    return Number(localStorage.getItem('rate') || '1');
  }
  saveCountryStates(countryStates) {
    this.countryStates = countryStates;
  }
  getCountryStates() {
    return this.countryStates;
  }

  getSavedCurrency() {
    const currency = localStorage.getItem('selectedCurrency');
    if (typeof currency !== 'undefined' && currency !== null && currency !== ''){
      return currency;
    }
    return null;
  }

  getStatesOfCountry(countryCode) {
    const country = this.countries.filter((countryData) => {
      if (countryData.name === countryCode){
        return true;
      }
    });
    if (typeof country !== 'undefined' && country !== null && country.length !== 0){
      return country[0].states;
    }
    else{
      return [];
    }
  }
  setActiveCountry(country) {
    this.selectedCountry = country;
  }
  getActiveCountry() {
    if (this.selectedCountry){
      return this.selectedCountry;
    }
    return null;
  }
  loadUserWallet(){
    this.WalletService.loadWalletAmount()
      .subscribe((response): void => {
        if (typeof response !== 'undefined' && response !== null && response !== ''){
          this.userWallet = response;
        }
        else{
          this.userWallet = 0;
        }

    },
    (error) => {
      this.userWallet = 0;
    });
  }
  saveUserWallet(walletAmount) {
    this.userWallet = walletAmount;
  }

  getUserWallet() {
    return this.userWallet;
  }
  getSiteSetting(navigate){
    this.http.get<any>(`get_site_settings`, { headers })
      .subscribe((response): void => {
        if (typeof response !== 'undefined' && response !== null && response !== ''){
          this.defaultCurrency = response.currency;
          if (response.payment_block){
            localStorage.setItem('a@7cti*&GH*UvePaKJI()U(yment*&^&*Fl%^ag', 'true');
          }else{
            localStorage.setItem('a@7cti*&GH*UvePaKJI()U(yment*&^&*Fl%^ag', 'false');
          }
          const loggedUser = localStorage.getItem('userId');
          if (typeof loggedUser !== 'undefined' && loggedUser !== null && loggedUser !== ''){
            this.myAccount.getProfile()
            .subscribe((responseData): void => {
              this.userProfile = responseData;
              if (typeof responseData !== 'undefined' && responseData !== null && responseData !== ''){
                localStorage.setItem('selectedCurrency', responseData.currency);
                const lastConvertingCurrecy = localStorage.getItem('lastCurrencyConvert');
                if (lastConvertingCurrecy !== ''){
                  const lastConvertDate = (new Date().getTime() - Number(lastConvertingCurrecy) || 1) / 86400000;
                  if (lastConvertDate > 1 && this.defaultCurrency !== responseData.currency){
                    const convertCurrencyCode = this.defaultCurrency + '_' + responseData.currency;
                    this.convertCurrency(convertCurrencyCode, navigate);
                  }
                }


              }
            },
            (error) => {
              this.userProfile = null;
            });
          }

        }
        else{
          this.defaultCurrency = '';
        }

    },
    (error) => {
      this.defaultCurrency = '';
    });
  }
  getDefaultCurrency() {
    return this.defaultCurrency;
  }
  getIsPaymentActiveOrNot(){
    return JSON.parse(localStorage.getItem('a@7cti*&GH*UvePaKJI()U(yment*&^&*Fl%^ag'));
  }
  setDirectAdminChat(flag: boolean){
    this.directAdminChat = flag;
  }
  getDirectAdminChat(){
    return this.directAdminChat;
  }
}
