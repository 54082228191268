<!-- <app-loader [isLoad]="isLoader" [isTransperent]="true"></app-loader> -->
<!--section start-->
<section class="login-page wpr section-big-py-space" [ngClass]="{'p10 hide': popUpLog, 'bg-light p80 ': !popUpLog}">
    <div class="custom-container wpr">
        <div class="row">
            <div class="col-lg-6" [ngClass]="{'col-popup': popUpLog, 'offset-lg-5': !popUpLog}">
                <div class="theme-card">
                    <i class="fa fa-close icon-close" (click)="closePopup()" *ngIf="popUpLog"></i>
                    <h3 class="text-center">Create account</h3>
                    <form [formGroup]="registerForm" (submit)="onSubmit()" class="theme-form">
                        <div class="form-row">
                            <div class="col-md-12 form-group">
                                <label for="email">Name <span class="mandatory">*</span></label>
                                <input type="text" name="name" formControlName="name" class="form-control" [ngClass]="{'has-error': !registerForm.get('name').valid && isFormSubmit}"  id="fname" placeholder="Full Name" >
                                <span class="error-msg" *ngIf='!registerForm.get("name").valid && isFormSubmit'>Name is required</span>
                            </div>

                        </div>
                        <div class="form-row">
                          <div class="col-md-6 form-group">
                              <label for="email">email <span class="mandatory">*</span></label>
                              <input type="text" name="email" class="form-control" [ngClass]="{'has-error': !registerForm.get('email').valid && isFormSubmit}" formControlName="email" id="email" placeholder="Email" >
                              <span class="error-msg" *ngIf="registerForm.get('email').hasError('required') && isFormSubmit">
                                email is required
                              </span>
                              <span class="error-msg" *ngIf="registerForm.get('email').hasError('email') && isFormSubmit">
                                incorrect email id
                              </span>
                          </div>
                          <div class="col-md-6 form-group">
                              <label for="review">Password <span class="mandatory">*</span></label>
                              <!-- <input type="password" name="pwd" class="form-control" [ngClass]="{'has-error': !registerForm.get('pwd').valid && isFormSubmit}" formControlName="pwd" id="review" placeholder="Password" pPassword [toggleMask]="true" > -->
                              <div style="width: 100%;">
                                <p-password formControlName="pwd" styleClass="form-control p-p-0" [toggleMask]="true" ></p-password>
                              </div>

                              <span class="error-msg" *ngIf="registerForm.get('pwd').hasError('required') && isFormSubmit">
                                password is required
                              </span>
                              <span class="error-msg" *ngIf="registerForm.get('pwd').hasError('minlength') && isFormSubmit">
                                The minimum length for password is {{registerForm.get('pwd').errors.minlength.requiredLength}} characters.
                              </span>
                              <span class="error-msg" *ngIf="registerForm.get('pwd').hasError('pattern') && isFormSubmit">
                                <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                                  <li>At least one lowercase</li>
                                  <li>At least one uppercase</li>
                                  <li>At least one numeric</li>
                              </ul>
                              </span>
                          </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4 form-group">
                                <label for="review" style="width: 100%;">Country code <span class="mandatory">*</span></label>
                                <app-country-code-flag [styleClassCustom]="'form-control-dropdown'"></app-country-code-flag>
                                <span class="error-msg" *ngIf='!registerForm.get("country_code").valid && isFormSubmit'>Country code is required</span>

                            </div>
                            <div class="col-md-8 form-group">
                                <label for="review" style="width: 100%;margin-left: 5px;">Phone <span class="mandatory">*</span></label>
                                <input type="text" name="phone" class="form-control" [ngClass]="{'has-error': !registerForm.get('phone').valid && isFormSubmit}" formControlName="phone" id="phone" placeholder="Phone Number" >
                                <span class="error-msg" *ngIf='!registerForm.get("phone").valid && isFormSubmit'>phone is required</span>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="col-md-12 form-group">
                              <p class="terms-condition" ><input type="checkbox" id="agrements" class="p-mr-2" [ngClass]="{'has-error': !registerForm.get('agrement').valid && isFormSubmit}" formControlName="agrement"> <label for="agrements">By filling this form, I agree to <a target="self" href="/user-terms-condition" class="blink-data" >Terms and conditions</a></label> </p>
                              <span class="error-msg" *ngIf='!registerForm.get("agrement").valid && isFormSubmit'>accept aggrement</span>
                            </div>
                          </div>
                          <div class="form-row">

                            <div class="col-md-12 form-group">
                                <button type="submit" class="btn btn-normal" >create Account</button>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-12 ">
                                <div class="wpr">

                                  <span style="float:left;">Have you already account?</span>
                                  

                                </div>
                                  <a (click)="navigatToRegUrl('/login',popUpLog)" class="btn_create_account">click here to Login</a>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <p-dialog [(visible)]="registerMsg" styleClass="userRegisterPopup" >
      <p-header>
        <div style="text-align: center;"> User Registaion</div>
      </p-header>
      <ng-container *ngIf="registerMsg?.type === 'error'">
        <div class="error-text">
          <i class="fa fa-times-circle" aria-hidden="true"></i>
        </div>
        <div class="text-danger text-center">{{registerMsg?.data}}</div>
      </ng-container>
      <ng-container *ngIf="registerMsg?.type === 'success'">
        <div class="success-text">
          <i class="fa fa-check-circle" aria-hidden="true"></i>
        </div>
        <div class="text-success text-center">{{registerMsg?.data}}</div>
      </ng-container>

      <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="registerMsg=''" label="Ok" styleClass="p-button-outlined"></p-button>
    </ng-template>
  </p-dialog>
</section>
<!--Section ends-->

