import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './Authentication/auth-guard.service';

import { AppComponent } from './app.component';


const routes: Routes = [
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'cart', loadChildren: () => import('./cart/cart.module').then(m => m.CartModule) },
  { path: 'about', loadChildren: () => import('./about/about.module').then(m => m.AboutModule) },
  { path: 'enq-cart/:id', loadChildren: () => import('./cart-enquiry/cart-enquiry.module').then(m => m.CartEnquiryModule) },
  { path: 'change-password', loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordModule) },
  { path: 'chat', loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule) },
  { path: 'contactus', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) },
  { path: 'shop/:id', loadChildren: () => import('./vendor-shop/vendor-shop.module').then(m => m.VendorShopModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'manage-order', loadChildren: () => import('./manage-order/manage-order.module').then(m => m.ManageOrderModule) },
  { path: 'my-account', loadChildren: () => import('./my-account/my-account.module').then(m => m.MyAccountModule) },
  { path: 'order-details/:id', loadChildren: () => import('./order-details/order-details.module').then(m => m.OrderDetailsModule) },
  { path: 'product-details/:id', loadChildren: () => import('./product-details/product-details.module').then(m => m.ProductDetailsModule) },
  { path: 'product-list/:id', loadChildren: () => import('./product-list/product-list.module').then(m => m.ProductListModule) },
  { path: 'register', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
  { path: 'user-terms-condition',
    loadChildren: () => import('./terms-condition/terms-condition.module').then(m => m.TermsConditionModule) },
  { path: 'vendor-terms-condition',
    loadChildren: () => import('./terms-condition/terms-condition.module').then(m => m.TermsConditionModule) },
  { path: 'privacy-policy', loadChildren: () => import('./terms-condition/terms-condition.module').then(m => m.TermsConditionModule) },
  { path: 'warrenty-policy', loadChildren: () => import('./terms-condition/terms-condition.module').then(m => m.TermsConditionModule) },
  { path: 'wishlist', loadChildren: () => import('./wishlist/wishlist.module').then(m => m.WishlistModule) },
  { path: 'vendor-register', loadChildren: () => import('./vendor-register/vendor-register.module').then(m => m.VendorRegisterModule) },
  { path: 'account-setting', loadChildren: () => import('./account-setting/account-setting.module').then(m => m.AccountSettingModule) },
  { path: 'user-wallet', loadChildren: () => import('./user-wallet/user-wallet.module').then(m => m.UserWalletModule) },
  { path: 'emailverify/:id', loadChildren: () => import('./verify-account/verify-account.module').then(m => m.VerifyAccountModule) },
  { path: 'customer-support', loadChildren: () => import('./customer-support/customer-support.module').then(m => m.CustomerSupportModule) },
  { path: 'account-menu-setting', loadChildren: () =>
                                      import('./account-menu-setting/account-menu-setting.module').then(m => m.AccountMenuSettingModule) },
  { path: 'forgot-password-change/:id', loadChildren: () =>
                                import('./forgot-password-change/forgot-password-change.module').then(m => m.ForgotPasswordChangeModule) },
  { path: 'order-success/:id',
  loadChildren: () => import('./order-success-error/order-success-error.module').then(m => m.OrderSuccessErrorModule) },
  { path: 'order-error/:id',
  loadChildren: () => import('./order-success-error/order-success-error.module').then(m => m.OrderSuccessErrorModule) },
  { path: 'order-cancel',
  loadChildren: () => import('./order-success-error/order-success-error.module').then(m => m.OrderSuccessErrorModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  //imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
