import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { RegisterRoutingModule } from './register-routing.module';
import { RegisterComponent } from './register.component';

/* import { LoaderModule } from '../loader/loader.module'; */
import { CountryCodeFlagModule } from '../country-code-flag/country-code-flag.module';
import {PasswordModule} from 'primeng/password';
import {DialogModule} from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';



@NgModule({
  declarations: [RegisterComponent],
  imports: [
    CommonModule,
    RegisterRoutingModule,
    PasswordModule,
    DialogModule,
    ButtonModule,
    /* LoaderModule, */
    CountryCodeFlagModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [RegisterComponent],
  bootstrap: [RegisterComponent]
})
export class RegisterModule { }
