<!-- <app-loader [isLoad]="isLoader" [isTransperent]="isTransperent"></app-loader> -->


<!--section start-->
  <section class="login-page section-big-py-space wpr" id="popup-cnt" [ngClass]="{'p10 hide': popUpLog, 'bg-light p80 ': !popUpLog}">
    <app-toast [message]="loginMsg" *ngIf="loginMsg"></app-toast>
    <div class="custom-container wpr">
        <div class="row">
            <div class="col-xl-5 col-lg-6 col-md-8" [ngClass]="{'col-popup': popUpLog, 'offset-xl-6 offset-lg-3 offset-md-2': !popUpLog}">
                <div class="theme-card">
                    <i class="fa fa-close icon-close" (click)="closePopup()" *ngIf="popUpLog"></i>
                    <h3 class="text-center">Login</h3>
                    <form [formGroup]="loginForm" (submit)="onSubmit()" class="theme-form" >
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input type="text" name="email" formControlName="email" class="form-control" [ngClass]="{'has-error': !loginForm.get('email').valid && isFormSubmit}" id="email" placeholder="Email" required>
                            <span class="error-msg" *ngIf='!loginForm.get("email").valid && isFormSubmit'>email is required</span>
                        </div>
                        <div class="form-group">
                            <label for="review">Password</label>
                            <input type="password" name="pwd" formControlName="pwd" class="form-control" [ngClass]="{'has-error': !loginForm.get('pwd').valid && isFormSubmit}" id="review" placeholder="Password" required>
                            <span class="error-msg" *ngIf='!loginForm.get("pwd").valid && isFormSubmit'>Password is required</span>
                        </div>
                        <button type="submit" class="btn btn-normal">Login</button>
                        <a class="float-right txt-default mt-2" (click)="forgotPwd()" id="fgpwd">Forgot your password?</a>
                    </form>
                    <p class="mt-3">Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click register.</p>
                    <a (click)="navigatToLogUrl('/register',popUpLog)" class="txt-default pt-3 d-block btn_create_account">Create an Account</a>
                </div>
            </div>
        </div>
    </div>

</section>
<p-dialog [(visible)]="forgotPwdMsg" styleClass="userRegisterPopup" >
  <p-header>
    <div style="text-align: center;"> Forgot Password</div>
  </p-header>
  <ng-container *ngIf="forgotPwdMsg?.type === 'error'">
    <div class="error-text">
      <i class="fa fa-times-circle" aria-hidden="true"></i>
    </div>
    <div class="text-danger text-center">{{forgotPwdMsg?.data}}</div>
  </ng-container>
  <ng-container *ngIf="forgotPwdMsg?.type === 'success'">
    <div class="success-text">
      <i class="fa fa-check-circle" aria-hidden="true"></i>
    </div>
    <div class="text-success text-center">{{forgotPwdMsg?.data}}</div>
  </ng-container>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="forgotPwdMsg=''" label="Ok" styleClass="p-button-outlined"></p-button>
</ng-template>
</p-dialog>
<!--Section ends-->
