import {Component} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { LoginService } from './login.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    template: `
        <section class="login-page section-big-py-space" id="popup-cnt">
          <form [formGroup]="forgotPwdForm">
            <div class="p-fluid p-grid">
              <div class="p-col-12">
              <span class="p-float-label">
                <input id="email" formControlName="email" pInputText>
                <label for="email">Email</label>
              </span>
              <small *ngIf="getForgotPasswordForm.email.touched && getForgotPasswordForm.email.invalid"
              class="p-invalid p-error">Email is required</small>
              </div>
              <div class="p-col-12">
              <button class="p-button-success create" [disabled]="!forgotPwdForm.valid" [loading]="isForgotMailLoading" pButton pRipple type="submit"
                label="Submit" (click)='payFee()'>
              </button>
              </div>
            </div>
          </form>
        </section>
    `
})
export class ForgotPassWord {

    isForgotMailLoading = false;
    forgotPwdForm: FormGroup;
    passwordChangeForm: FormGroup;
    constructor(private formBuilder: FormBuilder, private loginService: LoginService,
                public ref: DynamicDialogRef, private router: Router,
                public config: DynamicDialogConfig) { }

    ngOnInit() {
      this.forgotPwdForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]]
      });
      this.passwordChangeForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]]
      });
    }
    get getForgotPasswordForm() { return this.forgotPwdForm.controls; }
    payFee() {
      this.isForgotMailLoading = true;
      const formData = this.forgotPwdForm.value;
      const param = {
        email: formData.email
      };
      this.loginService.forgotPassword(param).subscribe((response): void => {
        const popUpData = {
          req: formData.email,
          res: response
        };
        this.ref.close(popUpData);
        this.isForgotMailLoading = false;
      },
      (error) => {
        this.isForgotMailLoading = false;
      });
    }
}
