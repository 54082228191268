import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NavigationRoutingModule } from './navigation-routing.module';
import { NavigationComponent } from './navigation.component';

import { LoginModule } from '../login/login.module';
import { RegisterModule } from '../register/register.module';
import { LoaderModule } from '../loader/loader.module';


@NgModule({
  declarations: [NavigationComponent],
  imports: [
    CommonModule,
    NavigationRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    LoginModule,
    RegisterModule,
    LoaderModule
  ],
  exports: [NavigationComponent]
})
export class NavigationModule { }
