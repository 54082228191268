import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { ForgotPassWord } from './forgot-password';
import { ToastModule } from '../toast/toast.module';
/* import { LoaderModule } from '../loader/loader.module'; */

import {DynamicDialogModule} from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import {DialogModule} from 'primeng/dialog';


@NgModule({
  declarations: [
    LoginComponent,
    ForgotPassWord
  ],
  imports: [
    CommonModule,
    DynamicDialogModule,
    DialogModule,
    InputTextModule,
    ButtonModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ToastModule,
    /* LoaderModule */
  ],
  exports: [LoginComponent]
})
export class LoginModule { }
