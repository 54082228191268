import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import {APP_BASE_HREF} from '@angular/common';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// - Authentication
import { AuthInterceptor } from './Authentication/auth.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoaderModule } from './loader/loader.module';
import { NavigationModule } from './navigation/navigation.module';
import { FooterModule } from './footer/footer.module';

import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';





@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ToastModule,
    LoaderModule,
    NavigationModule,
    FooterModule,
  ],
  providers: [{provide: APP_BASE_HREF, useValue: '/'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MessageService
    },
  ],
  bootstrap: [AppComponent],
  exports: [ToastModule]
})
export class AppModule { }
