import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { CountryCodeFlagRoutingModule } from './country-code-flag-routing.module';
import { CountryCodeFlagComponent } from './country-code-flag.component';
import {DropdownModule} from 'primeng/dropdown';


@NgModule({
  declarations: [CountryCodeFlagComponent],
  imports: [
    CommonModule,
    CountryCodeFlagRoutingModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [CountryCodeFlagComponent]
})
export class CountryCodeFlagModule { }
