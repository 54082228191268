import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  @Input() isLoad: boolean;
  @Input() isTransperent: boolean;
  @Input() page: string;
  @Input() limit: number;
  loaderLimit = [];
  constructor() { }

  ngOnInit(): void {
    for (let i = 0; i < this.limit; i++){
      this.loaderLimit.push(1);
    }
  }

}
