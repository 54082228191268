import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class CategoryModel {
    categoryList: [];
    constructor(){}
    getCategoryList() {
        return this.categoryList;
    }
    setCategoryList(categoryList) {
        this.categoryList = categoryList;
    }
}
