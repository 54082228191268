<!--footer start-->
<footer class="footer-2 wpr">
    <div class="container ">
      <div class="row">
        <div class="col-12">
          <div class="footer-main-contian">
            <div class="row ">
              <div class="col-lg-4 col-md-12 ">
                <div class="footer-left">
                  <div class="footer-logo">
                       <img src="assets/images/logo_white.png">
                  </div>
                  <div class="footer-detail">
                    <p>The B2B e-Commerce web portal helps you to expand your trading horizons beyond your imagination in a simple and secure manner.</p>
                    <ul class="paymant-bottom">
                      <li><a href="javascript:void(0)"><img src="assets/images/layout-1/pay/1.png" class="img-fluid" alt="pay"></a></li>
                      <li><a href="javascript:void(0)"><img src="assets/images/layout-1/pay/2.png" class="img-fluid" alt="pay"></a></li>
                      <li><a href="javascript:void(0)"><img src="assets/images/layout-1/pay/3.png" class="img-fluid" alt="pay"></a></li>
                      <li><a href="javascript:void(0)"><img src="assets/images/layout-1/pay/4.png" class="img-fluid" alt="pay"></a></li>
                      <li><a href="javascript:void(0)"><img src="assets/images/layout-1/pay/5.png" class="img-fluid" alt="pay"></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 col-md-12 ">
                <div class="footer-right">
                  <div class="row">
                    <div class="col-md-12">
                      <!-- <div class="subscribe-section">
                        <div class="row">
                          <div class="col-md-5 ">
                            <div class="subscribe-block">
                              <div class="subscrib-contant ">
                                <h4>subscribe to newsletter</h4>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-7 ">
                            <div class="subscribe-block">
                              <div class="subscrib-contant">
                                <div class="input-group" >
                                  <div class="input-group-prepend br0">
                                    <span class="input-group-text" ><i class="fa fa-envelope-o" ></i></span>
                                  </div>
                                  <input type="text" class="form-control" placeholder="your email" >
                                  <div class="input-group-prepend br0">
                                    <span class="input-group-text telly" ><i class="fa fa-paper-plane-o" ></i></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <div class="col-md-12">
                      <div class=account-right>
                        <div class="row">
                          <div class="col-md-4">
                            <div class="footer-box">
                              <div class="footer-title">
                                <h5>my account</h5>
                              </div>
                              <div class="footer-contant">
                                <ul>
                                  <li><a (click)="navigatToFooterUrl('/about')">about us</a></li>
                                  <li><a (click)="navigatToFooterUrl('/privacy-policy')">privacy policy</a></li>
                                  <li><a (click)="navigatToFooterUrl('/user-terms-condition')">terms & conditions</a></li>
                                  <li><a (click)="navigatToFooterUrl('/warrenty-policy')">warrenty policy</a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="footer-box">
                              <div class="footer-title">
                                <h5>quick link</h5>
                              </div>
                              <div class="footer-contant">
                                <ul>
                                  <!-- <li><a href="javascript:void(0)">Products</a></li> -->
                                  <li><a (click)="navigatToFooterUrl('/my-account')"> my account</a></li>
                                  <li><a (click)="navigatToFooterUrl('/cart')"> my cart</a></li>
                                  <li><a (click)="navigatToFooterUrl('/register')"> Register</a></li>
                                  <li><a (click)="navigatToFooterUrl('/contactus')"> contact us</a></li>
                                  <li><a (click)="navigatToFooterUrl('/login')">Login </a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-5">
                            <div class="footer-box footer-contact-box">
                              <div class="footer-title">
                                <h5>contact us</h5>
                              </div>
                              <div class="footer-contant">
                                <ul class="contact-list">
                                  <li><i class="fa fa-envelope-o"></i><span style="text-transform: none;">support@wikimarkets.com</span></li>
                                  <li><a (click)="chatOpen()"><i class="fa fa-comments-o"></i><span>chat</span></a></li>
                                </ul>
                                   <div class="app-item-group ">
              <div class="sosiyal-block" >
                <h6>follow us</h6>
                <ul class="sosiyal">
                  <li><a href="javascript:void(0)" class="sosiyal_icon"><i class="fa fa-facebook" ></i></a></li>
                  <li><a href="javascript:void(0)" class="sosiyal_icon"><i class="fa fa-google-plus" ></i></a></li>
                  <li><a href="javascript:void(0)" class="sosiyal_icon"><i class="fa fa-twitter" ></i></a></li>
                  <li><a href="javascript:void(0)" class="sosiyal_icon"><i class="fa fa-instagram" ></i></a></li>
                  <li><a href="javascript:void(0)" class="sosiyal_icon"><i class="fa fa-rss" ></i></a></li>
                </ul>
              </div>
            </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="sub-footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="sub-footer-contain">
              <p><span>Copyright 2021 </span>| All Rights Reserved</p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </footer>
  <!--footer end-->


  <!-- tap to top -->
  <div class="tap-top" *ngIf="isScrolled" (click)="scrollToTop()">
      <div>
          <i class="fa fa-angle-double-up"></i>
      </div>
  </div>
  <!-- tap to top End -->
