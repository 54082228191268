import { Component, Input, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisterService } from './register.service';
import { ProductsService } from '../product.service';
import { MyAccountService } from '../my-account/my-account.service';
import { ActivatedRoute, Router } from '@angular/router';
declare var $;
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  isLoader = false;
  registerMsg: any;
  isFormSubmit = false;
  countryCode = [];
  showHide = false;
  isVerifyMobile = false;
  verifyOtp: number;
  @Input() popUpLog: boolean;
  @Output() logRegShift = new EventEmitter();
  constructor(private formBuilder: FormBuilder, private router: Router, private commonService: ProductsService,
              private registerService: RegisterService, private accountService: MyAccountService) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      country_code: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      agrement: [false, Validators.requiredTrue],
      pwd: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/)]]
    });
    setTimeout(() => {
      this.countryCode = this.commonService.getCountries();
    }, 3000);

  }
  onSubmit() {
    const countryCode = this.commonService.getActiveCountry();
    this.isFormSubmit = true;
    if (countryCode !== null){
      this.registerForm.get('country_code').setValue(countryCode.dialCode);
    }

    if (this.registerForm.valid){
      this.isLoader = true;
      this.registerService.UserRegister(this.registerForm.value)
        .subscribe((response): void => {
          if (response.status === 'success') {
            localStorage.setItem('accessValidation', response?.user_data?.token);
            this.registerMsg = {
              type: 'success',
              head: 'Success !',
              data: 'User register successfull!!! Please wait while Email Verification send.'
            };
            this.mailVarificationRequest();
            this.registerForm.reset();
            Object.keys(this.registerForm.controls).forEach((key) => {
              const control = this.registerForm.controls[key];
              control.setErrors(null);
            });
          }
          else{
            this.isFormSubmit = false;
            if (response.message === ''){
              this.registerMsg = {
                type: 'error',
                head: 'Error !',
                data: 'Registration Failed. Please try again.'
              };
            }
            else{
              this.registerMsg = {
                type: 'error',
                head: 'Error !',
                data: response.message
              };
            }
          }
          window.scroll(0, 0);
          setTimeout(() => {
            this.registerMsg = null;
          }, 10000);
          this.isLoader = false;
        },
          (error) => {
            this.isLoader = false;
            this.registerMsg = {
              type: 'error',
              head: 'Error !',
              data: 'Registration Failed. Please try again.'
            };
            window.scroll(0, 0);
            setTimeout(() => {
              this.registerMsg = null;
            }, 10000);
          });
    }

  }
  closePopup() {
    $('#popup-login').hide();
  }
  navigatToRegUrl(url, isPopup) {
    if (!isPopup){
      this.router.navigate([url]);
    }
    else{
      this.logRegShift.emit(false);
    }
  }
  mailVarificationRequest(){
    this.isLoader = true;
    this.accountService.emailVerificationRequest()
    .subscribe((response): void => {
      if (response.status === 'success'){
        this.isVerifyMobile = true;
        this.registerMsg = {
          type: 'success',
          head: 'Success !',
          data: 'Email verification mail send. Please check your registered e-mail.'
        };
      } else{
        this.registerMsg = {
          type: 'error',
          head: 'Error !',
          data: 'Email verification mail not send'
        };
      }
      this.isLoader = false;
    },
    (error) => {
      this.registerMsg = {
        type: 'error',
        head: 'Error !',
        data: 'Email verification mail not send'
      };
      this.isLoader = false;
    });
  }


}
