import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ProductsService } from '../product.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  isScrolled = false;
  constructor(private router: Router, private productService: ProductsService) { }
  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    this.isScrolled = $event?.target?.scrollingElement?.scrollTop >= 200 ? true : false;
  }
  ngOnInit(): void {
  }
  scrollToTop() {
    (function smoothscroll() {
        const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
            window.requestAnimationFrame(smoothscroll);
            window.scrollTo(0, currentScroll - (currentScroll / 20));
        }
    })();
}
navigatToFooterUrl(url) {
  this.router.navigate([url]);
}
chatOpen(){
  this.productService.setDirectAdminChat(true);
  this.navigatToFooterUrl('/chat');
}

}
