import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

let headers;

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient
  ) {
  }

  login = (sendingData): Observable<any> => {
    const data = {
      ...sendingData,
      password: sendingData.pwd
    }
    return this.http.post<any>('login', data, { headers });
  }
  vendorLogin = (): Observable<any> => {
    return this.http.post<any>('vendor_login', "", { headers });
  }
  forgotPassword = (data): Observable<any> => {
    return this.http.post<any>('forgot_password', data, { headers });
  }


}
