import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

let headers;

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getProfile = (): Observable<any> => {
    return this.http.get<any>('my_account');
  }

  updateName = (data): Observable<any> => {
    return this.http.post<any>('my_account_edit/name', data, {headers});
  }

  updateEmail = (data): Observable<any> => {
    return this.http.post<any>('my_account_edit/email', data, {headers});
  }

  updatePhone = (data): Observable<any> => {
    return this.http.post<any>('my_account_edit/phone', data, {headers});
  }

  loadAddress  = (): Observable<any> => {
    return this.http.get<any>(`user_address/get`, { headers });
  }

  saveAddress  = (data): Observable<any> => {
    return this.http.post<any>(`user_address/add`, data , { headers });
  }

  updateAddress = (data, addressId): Observable<any> => {
    return this.http.post<any>(`user_address/${addressId}/edit`, data , { headers });
  }

  deleteAddress = (addressId): Observable<any> => {
    return this.http.post<any>(`user_address/${addressId}/delete`, {} , { headers });
  }

  saveOrUpdateCurrency = (data): Observable<any> => {
    return this.http.post<any>(`my_account_edit/currency`, data , { headers });
  }

  getAllRedeemRequest = (): Observable<any> => {
    return this.http.get<any>(`get_all_redeem_request`, { headers });
  }
  getSiteSetting  = (): Observable<any> => {
    return this.http.get<any>(`get_site_settings`, { headers });
  }

  emailVerificationRequest = (): Observable<any> => {
    return this.http.post<any>(`verify/email`, '' , { headers });
  }
  emailVerification = (data): Observable<any> => {
    return this.http.post<any>(`verify/email/otp`, data , { headers });
  }
  phoneVerificationRequest = (): Observable<any> => {
    return this.http.post<any>(`verify/phone/request`, '' , { headers });
  }
  phoneVerification = (data): Observable<any> => {
    return this.http.post<any>(`verify/phone/otp`, data , { headers });
  }

}
