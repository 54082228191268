<!-- loader start -->
<div [ngClass]="{'loader-wrapper': true, 'isTransperent': isTransperent}" *ngIf="isLoad && !page">
  <div>
      <img src="../../assets/images/loader.gif" alt="loader">
  </div>
</div>
<!-- loader end -->

<!-- HOME PAGE SLIDER start -->
<div class="custom-skeleton p-p-4" *ngIf="page === 'banner'">
  <div class="p-d-flex p-mb-3 loader">
      <div style="width: 50%;">
          <p-skeleton width="80%" height="1.75rem" styleClass="p-mb-2"></p-skeleton>
          <p-skeleton width="90%" height="2rem" styleClass="p-mb-2"></p-skeleton>
          <div class="p-d-flex p-jc-between p-mt-3">
              <p-skeleton width="4rem" height="1.5rem"></p-skeleton>
          </div>
      </div>
      <div style="width: 50%;height: 100%;">
        <p-skeleton width="100%" height="100%" borderRadius="16px"></p-skeleton>
      </div>
  </div>
</div>
<!-- HOME PAGE SLIDER end -->


<div class="custom-skeleton p-p-4" *ngIf="page === 'list-vertical'">
  <ng-container *ngFor="let count of loaderLimit">
    <div class="p-d-flex p-mb-3 loader-list">
        <p-skeleton shape="circle" size="4rem" styleClass="p-mr-2"></p-skeleton>
        <div>
            <p-skeleton width="10rem" styleClass="p-mb-2"></p-skeleton>
        </div>
    </div>
  </ng-container>
</div>

<div class="custom-skeleton p-p-4" *ngIf="page === 'list-horizontal'">
  <div class="p-d-flex p-flex-row">
    <ng-container *ngFor="let count of loaderLimit">
      <div class="p-d-flex p-flex-column p-mx-4 loader-list">
        <p-skeleton width="7rem" height="7rem" borderRadius="10px"></p-skeleton>
          <div>
              <p-skeleton width="7rem" styleClass="p-my-2"></p-skeleton>
          </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="custom-skeleton p-p-4" *ngIf="page === 'product-list'">
  <div class="p-d-flex pDWrap">
    <ng-container *ngFor="let count of loaderLimit">
      <div class="p-col-12 p-md-6 p-lg-4">
        <p-skeleton width="100%" height="13rem" borderRadius="10px"></p-skeleton>
          <div>
              <p-skeleton width="100%" styleClass="p-my-2"></p-skeleton>
              <p-skeleton width="100%" styleClass="p-my-2"></p-skeleton>
              <p-skeleton width="100%" styleClass="p-my-2"></p-skeleton>
          </div>
      </div>
    </ng-container>
  </div>
</div>


<div class="custom-skeleton p-p-4" *ngIf="page === 'product-det-img'">
  <div class="p-d-flex pDWrap">
    <ng-container >
      <div class="p-col-12">
        <p-skeleton width="100%" height="13rem" borderRadius="10px"></p-skeleton>
      </div>
    </ng-container>
  </div>
</div>
