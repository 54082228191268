import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CategoryModel } from '../models/category.model';
import { ProductSearchModel } from '../models/product.model';
import { BehaviorSubject } from 'rxjs';

let headers;

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    private http: HttpClient,
    private categoryList: CategoryModel,
    private productSearch: ProductSearchModel
  ) {
  }

  getAllCategoryList = (sortOrder = ''): Observable<any> => {
    return this.http.get<any>(`get_category${sortOrder}`);
  }
  getNotificationCount = (): Observable<any> => {
    return this.http.post<any>('notification_count', '', {headers});
  }
  getCategoryList() {
    return this.categoryList.categoryList || [];
  }
  setCategoryList(data) {
    this.categoryList.categoryList = data;
  }
  setProductSearchData(data) {
    this.productSearch.cate_id = data.cate_id;
    this.productSearch.subcate_id = data.subcate_id;
    this.productSearch.brand_id = data.brand_id;
    this.productSearch.search_key = data.search_key;
    this.productSearch.min_price = data.min_price;
    this.productSearch.max_price = data.max_price;
    this.productSearch.customer_id = data.customer_id;
  }
  vendorLogin = (): Observable<any> => {
    const data = {};
    return this.http.post<any>('vendor_login', data, { headers });
  }
  getSearchableProductList = (data): Observable<any> => {
    return this.http.post<any>('get_search_suggestion', data, { headers });
  }

}
