import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

let headers;

@Injectable({
  providedIn: 'root'
})
export class UserWalletService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getProfile = (): Observable<any> => {
    return this.http.get<any>('my_account');
  }

  loadWalletAmount = (): Observable<any> => {
    return this.http.post<any>(`my_wallet`, '' , { headers });
  }
  redeamWalletAmount  = (data): Observable<any> => {
    return this.http.post<any>(`redeem_request`, data , { headers });
  }
  getAllRedeemRequest = (): Observable<any> => {
    return this.http.get<any>(`get_all_redeem_request`, { headers });
  }
  walletTxnHistory  = (data): Observable<any> => {
    return this.http.post<any>(`my_wallet/history`, data , { headers });
  }

}
