import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class ProductSearchModel {
    cate_id: number;
    subcate_id: number;
    brand_id: number;
    search_key: string;
    min_price: number;
    max_price: number;
    rating: number;
    main_product_id: number;
    featured_list = false;
    customer_id: any;
    constructor(){}
}
