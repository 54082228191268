import { Component, OnInit, Input } from '@angular/core';
import { ProductsService } from '../product.service';
interface Country {
  name: string;
  currency: string;
  dialCode: string;
  flag: string;
}
@Component({
  selector: 'app-country-code-flag',
  templateUrl: './country-code-flag.component.html',
  styleUrls: ['./country-code-flag.component.css']
})
export class CountryCodeFlagComponent implements OnInit {
  countryList: Country[];
  selectedCountry: Country;
  searchCountryData = '';
  searchedCountryList = [];
  @Input() value: any;
  @Input() styleClassCustom: any;
  constructor(private commonService: ProductsService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.countryList = this.commonService.getCountries();
      if (this.value){
        [this.selectedCountry] = this.countryList.filter(country => country.dialCode === this.value);
      }else{
        this.selectedCountry = {
          currency: 'AED',
          dialCode: '971',
          flag: 'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg',
          name: 'United Arab Emirates',
        };
        this.selectCountry();
      }
    }, 3000);
  }
  selectCountry() {
    this.commonService.setActiveCountry(this.selectedCountry);
  }

}
