import { Component, OnInit } from '@angular/core';
import { ProductsService } from './product.service';
import { PrimeNGConfig } from 'primeng/api';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  loaderStatus = true;
  isNavShow = true;
  constructor(private commonService: ProductsService, private primengConfig: PrimeNGConfig) { }


  ngOnInit() {
    this.primengConfig.ripple = true;
    this.loadCountries();
    this.loadCountryStates();
    setTimeout(() => {
      this.loaderStatus = false;
    }, 2000);
  }
  countChangedHandler(isNavShow: boolean) {
    /* alert(isNavShow); */
  }
  loadCountries = async () => {
    const response = await this.commonService.loadCountries().toPromise();
    try {
      if (typeof response !== 'undefined' && response !== null && response !== ''){
        this.commonService.saveCountries(response.data);
      }
    } catch (error) {
      this.loaderStatus = false;
    }
  }
  loadCountryStates = async () => {
    const response = await this.commonService.loadCountryStates().toPromise();
    try {
      if (typeof response !== 'undefined' && response !== null && response !== ''){
        this.commonService.saveCountryStates(response.data);
      }
    } catch (error) {
      this.loaderStatus = false;
    }
  }
  /* loadCountryCurrencies = async () =>{
    const response = await this.commonService.loadCountryCurrency().toPromise();
    try {
      if(typeof response !== "undefined" && response !== null && response !== ""){
        this.commonService.saveCountryCurrency(response.data);
      }
    } catch (error) {
      this.loaderStatus = false;
    }
  } */
}
