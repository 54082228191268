import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationService } from './navigation.service';
import { ProductsService } from '../product.service';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalUrls } from '../global-url';
import { debounceTime } from 'rxjs/operators'
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { Subscription } from 'rxjs';
declare var $;
declare var google;
@Component({
  selector: 'app-navigation',
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        'max-height': '200px',
        /* opacity: 1, */
      })),
      state('closed', style({
        height: '0px',
        padding: '0px 10px'
        /* opacity: 0, */
      })),
      transition('open => closed', [
        animate('0.1s')
      ]),
      transition('closed => open', [
        animate('0.1s')
      ]),
    ]),
    trigger('toggleShow', [
      state('hide-nav', style({
        height: '0px',
        overflow: 'hidden',
      })),
      transition('* => *', animate('200ms ease-out'))
    ]),
  ],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit, AfterViewInit {
  @Input() visibleType: string;
  @Output() navigationCategoryChange = new EventEmitter<string>();
  morSlideToggle = true;
  isNavShow = true;
  isLoggedIn = false;
  categoryList: any;
  userDet = null;
  categorySearchForm: FormGroup;
  navCounts: any = { cart: 0, message: 0, wish_list: 0 };
  searchableProductList = [];
  isSearchVisible = false;
  $subs: Subscription;
  productListUrl = '';
  globalUrl = '';
  current_url = '';
  //userDet = '';
  personalData = '';
  isRegOrLog = false;
  loaderStatus = true;
  search_top_blk_wpr = 0;
  topCategoryList: any;
  productNameSelected = false;
  public address: any = {}
  googleMaoAddress: any;
  countryList: any[];
  currency:any = "AED";


  constructor(private navService: NavigationService, private formBuilder: FormBuilder, private location: Location, private router: Router,
    private productSearchServices: ProductsService, private globalUrls: GlobalUrls) {
    this.productSearchServices.loadCountries().subscribe(res=>{
      this.productSearchServices.saveCountries(res.data);
      this.countryList = this.filterUniqueByCurrency(this.productSearchServices.getCountries())
    })
    this.currency = localStorage.getItem('selectedCurrency');
    if(!this.currency)
      {
        this.currency = "AED";
      }
        this.$subs = this.productSearchServices.getNotificationDataList().subscribe((data) => {
      const userLogin = localStorage.getItem('tocken');
      if (typeof userLogin !== 'undefined' && userLogin !== null && userLogin !== '') {
        /* this.getNavigationCount(); */
        this.isLoggedIn = true;
      }
      else {
        this.isLoggedIn = false;
        this.navCounts = { cart: 0, message: 0, wish_list: 0 };
      }

    });
      this.address = JSON.parse(localStorage.getItem('location') || '{}');

    router.events.subscribe((val) => {
      const userLogin = localStorage.getItem('tocken');
      this.globalUrl = this.globalUrls.imgUrl;
      this.isNavShow = !location.path().includes('/') ? true : false;
      if (val instanceof NavigationEnd) {
        if (location.path() === '') {
          if (typeof userLogin !== 'undefined' && userLogin !== null && userLogin !== '') {
            this.isLoggedIn = true;
          }
        }

      }
    });
  }
  filterUniqueByCurrency(items) {
    const uniqueItemsMap = {};

    return items.filter(item => {
      if (!uniqueItemsMap[item.currency] && item.currency) {
        uniqueItemsMap[item.currency] = true;
        return true;
      }
      return false;
    });

  }
  ngOnInit(): void {
    const userLogin = localStorage.getItem('tocken');
    if (typeof userLogin !== 'undefined' && userLogin !== null && userLogin !== '') {
      this.isLoggedIn = true;
    }
    this.categorySearchForm = this.formBuilder.group({
      category: ['', Validators.required],
      product: ['', Validators.required]
    });
    this.getAllCategory();
    if (!this.visibleType) {
      if (this.isLoggedIn) {
        this.getNavigationCount();
      }
    }
    /* this.router.events.filter(event => event instanceof NavigationEnd).subscribe(event =>  this.getNavigationCount()); */

    const productName = this.categorySearchForm.controls['product'];
    productName.valueChanges
      .pipe(debounceTime(500))
      .subscribe(dName => {
        if (!this.productNameSelected) {
          this.getSearchableProductList();
        }
        this.productNameSelected = false;
      });
  }

  moreCategoryViewClick() {
    this.morSlideToggle = this.morSlideToggle ? false : true;
  }
  getAllCategory() {
    if (this.visibleType === 'rounded-category') {
      this.navService.getAllCategoryList('?sort_order=true')
        .subscribe((response): void => {
          this.topCategoryList = response;
          /* this.navService.setCategoryList(response || []); */
          setTimeout(() => {
            this.setSlickCategories();
            this.loaderStatus = false;
          }, 2000);
          /* this.categorySearchForm.get('category').setValue(response[0].id); */
        },
          (error) => {
            this.loaderStatus = false;
          });
      return;
    }
    this.categoryList = this.navService.getCategoryList();
    if (this.categoryList?.length) {
      setTimeout(() => {
        this.setSlickCategories();
        this.loaderStatus = false;
      }, 2000);
    }
    else {
      this.navService.getAllCategoryList()
        .subscribe((response): void => {
          this.categoryList = response;
          this.navService.setCategoryList(response || []);
          setTimeout(() => {
            this.setSlickCategories();
            this.loaderStatus = false;
          }, 2000);
          /* this.categorySearchForm.get('category').setValue(response[0].id); */
        },
          (error) => {
            this.loaderStatus = false;
          });
    }
    /*  */
  }
  setSlickCategories() {
    if ($('.slide-6').hasClass('slick-slider')) {
      $('.slide-6').slick('unslick');
    }


    $('.slide-6').slick({
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 8,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1367,
          settings: {
            slidesToShow: 7.2,
            slidesToScroll: 6,
            infinite: false
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6.2,
            slidesToScroll: 5,
            infinite: false
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 5.5,
            slidesToScroll: 4,
            infinite: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 5.2,
            slidesToScroll: 4,
            infinite: false
          }
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: 4.6,
            slidesToScroll: 4,
            infinite: false
          }
        }

      ]
    });
  }
  onSubmit() {
    const formData = this.categorySearchForm.value;
    const customerId = localStorage.getItem('userId') ? localStorage.getItem('userId') : 0;
    /* formData.product = this.slugify.transform(formData.product);
    const lstUrlCharacter = formData.product.substr(formData.product.length - 1);
    if (lstUrlCharacter === '-'){
      formData.product = formData.product.slice(0, -1);
    } */
    const data = {
      cate_id: formData.category,
      subcate_id: undefined,
      brand_id: undefined,
      search_key: formData.product,
      min_price: undefined,
      max_price: undefined,
      customer_id: customerId,
      page: 1
    };
    this.isSearchVisible = false;
    this.navService.setProductSearchData(data);
    const url = this.productSearchServices.encodeUrlParamData(data);
    /* this.productSearchServices.setSearchDataList(data);
    let url = this.slugify.transform(formData.product);
    const lst_url_ch = url.substr(url.length - 1);
    if (lst_url_ch === '-'){
      url = url.slice(0, -1);
    }
    if (typeof url === 'undefined' || url === null || url === ''){
      url = 'all';
    } */
    $('.search-overlay').fadeOut();
    this.categorySearchForm.get('category').setValue('');
    this.router.navigate([`/product-list/${url}`]);


  }
  onNavigatePage(id) {

  }
  getNavigationCount() {
    this.navService.getNotificationCount()
      .subscribe((response): void => {
        this.navCounts = response;
      },
        (error) => {

        });
  }
  listAllCategoryProducts(category) {
    const data = {
      cate_id: category.id,
      subcate_id: undefined,
      brand_id: undefined,
      search_key: undefined,
      min_price: undefined,
      max_price: undefined,
    };
    /* this.productListUrl = this.slugify.transform(category.title);
    this.navService.setProductSearchData(data);
    console.log(data);

    this.router.navigate([`/product-list/${this.productListUrl}`]); */
    const url = this.productSearchServices.encodeUrlParamData(data);
    this.router.navigate([`/product-list/${url}`]);
  }
  locationSelectPopup(type) {

    const map_sel_popup = document.getElementById('map_sel_popup');
    if(type == "open"){
      map_sel_popup.classList.add('show');
    }else{
      map_sel_popup.classList.remove('show');
    }

  }
  sellMyProduct() {
    if (this.isLoggedIn) {
      const userType = localStorage.getItem('userType');

      this.navService.vendorLogin()
        .subscribe((response): void => {
          if (typeof response === 'undefined' || response === null || response === '') {
            this.router.navigate([`/vendor-register`]);
          }
        },
          (error: HttpErrorResponse) => {
            if (error instanceof HttpErrorResponse) {
              if (error.error.text !== '') {
                window.open(error.error.text, '_self');
              }
              else {
                this.router.navigate([`/vendor-register`]);
              }
            }
          });
    }
    else {
      this.router.navigate([`/login`]);
    }
    /*  */

  }
  getSearchableProductList() {
    const formData = this.categorySearchForm.value;
    const customerId = localStorage.getItem('userId') ? localStorage.getItem('userId') : 0;

    if (formData.product === '') {
      this.isSearchVisible = false;
    }

    const data = {
      cate_id: formData.category || '',
      key: formData.product,
      customer_id: customerId
    };
    if (formData.product !== '') {
      this.navService.getSearchableProductList(data)
        .subscribe((response): void => {
          if (typeof response !== 'undefined' && response !== null && response.length !== 0) {
            this.searchableProductList = response;
            this.isSearchVisible = true;
          }
        },
          (error) => {

          });
    }
    else {
      this.isSearchVisible = false;
      this.searchableProductList = [];
    }

  }
  selectProduct(data) {
    if (typeof data !== 'undefined' && data !== null && data !== '') {
      this.categorySearchForm.get('product').setValue(data);


      setTimeout(() => {
        this.onSubmit();
      }, 500);

    }
    else {
      this.categorySearchForm.get('product').setValue('');
    }
    this.productNameSelected = true;
    this.isSearchVisible = false;
    this.searchableProductList = [];
  }
  toggleSettingOpen() {
    $('.sm-horizontal').css('right', '0px');
  }
  toggleSettingClose() {
    $('.sm-horizontal').css('right', '-410px');
  }

  toggleNavOpen() {
    $('.nav-slide').css('left', '0px');
  }
  toggleNavClose() {
    $('.nav-slide').css('left', '-410px');
  }
  closeMobSearch() {
    $('.search-overlay').fadeOut();
  }
  openMobSearch() {
    $('.search-overlay').show();
  }
  navigatToUrl(url) {
    this.current_url = url;
    this.router.navigate([url]);
  }
  navisRegOrLog(selectedFlag: boolean) {
    this.isRegOrLog = selectedFlag;
    if (!selectedFlag) {
      setTimeout(() => {
        $('#popup-cnt').show();
      }, 100);
    }
  }
  quatationTextboxfocusOut() {
    setTimeout(() => {
      this.isSearchVisible = false;
    }, 100);
  }

  loadCategoryProducts(category) {
    const data = {
      cate_id: category.id,
      subcate_id: undefined,
      brand_id: undefined,
      search_key: undefined,
      min_price: undefined,
      max_price: undefined,
    };
    /* this.productListUrl = this.slugify.transform(category.title);

    this.homeService.setProductSearchable(data);
    this.router.navigate([`/product-list/${this.productListUrl}`]); */
    const url = this.productSearchServices.encodeUrlParamData(data);
    this.router.navigate([`/product-list/${url}`]);
  }

  categoryValueChange(value: string) {
    this.navigationCategoryChange.emit(value);
  }

  onMobSearch(val) {
    this.search_top_blk_wpr = val;
  }

  settingsNavigationTopMenu(menu) {
    this.current_url = menu;
    this.router.navigate([menu]);
  }
  logout() {
    localStorage.clear();
    this.productSearchServices.setNotificationData('loggedout');
    this.router.navigate([`/login`]);
  }
  ngAfterViewInit(): void {
    let markers: google.maps.Marker[] = [];
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        center: { lat: -33.8688, lng: 151.2195 },
        zoom: 13,
         zoomControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
        mapTypeId: "roadmap",
      }
    );
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          map.setCenter(pos);
          // Clear out the old markers.
          markers.forEach((marker) => {
            marker.setMap(null);
          });
          markers = [];
          const userLocation = new google.maps.LatLng(pos.lat, pos.lng);
          this.placeMarker(userLocation, map, markers);


        },
        () => {
        }
      );
    }
    google.maps.event.addListener(map, 'click', (event)=> {
      // Clear out the old markers.
          markers.forEach((marker) => {
            marker.setMap(null);
          });
          markers = [];

      this.placeMarker(event.latLng, map, markers);
    });
    // Create the search box and link it to the UI element.
    const input = document.getElementById("pac-input") as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);

    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

    // Bias the SearchBox results towards current map's viewport.
    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
    });

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();

      if (places.length == 0) {
        return;
      }

      // Clear out the old markers.
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();

      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }

        const icon = {
          url: place.icon as string,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };

        // Create a marker for each place.
        this.placeMarker(place.geometry.location, map, markers);

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    });
  }
  placeMarker(location: google.maps.LatLng, map, markers) {
    const marker = new google.maps.Marker({
      position: location,
      map: map,
      draggable: true
    });
    google.maps.event.addListener(marker, 'dragend', () => {
      this.setAddress(marker);
    });
    this.setAddress(marker);
    markers.push(marker);
  }
  setAddress(marker) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'latLng': marker.getPosition() }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK && Array.isArray(results) && results.length > 0) {
        this.address = results[0];
        this.googleMaoAddress = this.address;
         document.getElementById('selected_location_txt').innerHTML = this.googleMaoAddress.formatted_address;
        localStorage.setItem('location',JSON.stringify(this.address))
      }
    })
  }


changeCurrency(){
localStorage.setItem('selectedCurrency',this.currency);
this.productSearchServices.convertCurrency(this.currency,this.router.url);
}

  confirmLocation() {
    localStorage.setItem('selectedCurrency',this.currency);
            this.locationSelectPopup('close');
  }


}
