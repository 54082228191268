import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CountryCodeFlagComponent } from './country-code-flag.component';

const routes: Routes = [{ path: '', component: CountryCodeFlagComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CountryCodeFlagRoutingModule { }
