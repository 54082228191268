import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalUrls {
  imgUrl = environment.BASE_URL;
  currencyAPIKey = environment.currencyAPIKey;
  constructor() {
  }

}
